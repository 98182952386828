import bg from '../assets/bg.webp';
import gallery01 from '../assets/gallery01.webp';
import gallery02 from '../assets/gallery02.webp';
import gallery03 from '../assets/gallery03.webp';
import gallery04 from '../assets/gallery04.webp';
import gallery05 from '../assets/gallery05.webp';
import gallery06 from '../assets/gallery06.webp';
import gallery07 from '../assets/gallery07.webp';
import gallery08 from '../assets/gallery08.webp';
import gallery09 from '../assets/gallery09.webp';
import gallery10 from '../assets/gallery10.webp';
import gallery11 from '../assets/gallery11.webp';
import gallery12 from '../assets/gallery12.webp';
import gallery13 from '../assets/gallery13.webp';
import gallery14 from '../assets/gallery14.webp';
import gallery15 from '../assets/gallery15.webp';
import gallery16 from '../assets/gallery16.webp';
import gallery17 from '../assets/gallery17.webp';
import gallery18 from '../assets/gallery18.webp';
import gallery19 from '../assets/gallery19.webp';
import jepun from '../assets/jepun.webp';
import jepunflower from '../assets/jepunflower.webp';
import spoon from '../assets/spoon.svg';
import steak from '../assets/steak.webp';

export default {
  steak,
  bg,
  jepunflower,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  gallery08,
  gallery09,
  gallery10,
  gallery11,
  gallery12,
  gallery13,
  gallery14,
  gallery15,
  gallery16,
  gallery17,
  gallery18,
  gallery19,
  spoon,
  jepun,
};
